<template>
  <ConfirmDialog
    :value.sync="show"
    :title="title"
    :loading="loading"
    maxWidth="600px"
    :positiveButtonText="buttonText"
    dontHideOnPositiveAction
    showCloseBtn
  >
    <v-layout column slot="content">
      <P1MedicalPlaceForm
        ref="placeform"
        :medicalPlace.sync="medicalPlace"
      ></P1MedicalPlaceForm>
    </v-layout>
    <v-layout column class="mx-4" slot="footer">
      <v-flex shrink v-if="messageType != 0 && showMessage">
        <v-fade-transition>
          <Message
            :type="messageType"
            :text="message"
            @close="hideMessage"
          ></Message>
        </v-fade-transition>
      </v-flex>
      <v-layout align-center :class="messageType != 0 && showMessage ? 'mt-2' : ''">
        <v-spacer></v-spacer>
        <DefaultButton @click.stop="cancel"
          >Anuluj</DefaultButton
        >
        <DefaultButton
          primary
          class="mx-0"
          @click.stop="handlePositiveAction"
          >{{ buttonText }}</DefaultButton
        >
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import P1Service from "@/services/p1.service";
import CRUDMixin from "@/mixins/CRUDMixin";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    edit: {},
    medicalPlace: {},
  },
  data() {
    return {
      initialMedicalPlace: { ...this.medicalPlace },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    title() {
      return this.edit ? "Edytuj miejsce" : "Dodaj miejsce";
    },
    buttonText() {
      return this.edit ? "Zapisz" : "Dodaj";
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    P1MedicalPlaceForm: () =>
      import("@/components/forms/p1/P1MedicalPlaceForm"),
    Message: () => import("@/components/Message"),
  },
  methods: {
    add() {
      if (!this.$refs.placeform.validate()) {
        return;
      }

      this.beforeRequest();

      P1Service.createMedicalPlace(this.medicalPlace)
        .then((reply) => {
          console.log("REPLY:", reply);
          this.reset();
          this.$emit("success", "Miejsce udzielania świadczeń zostało dodane.");
          this.show = false;
        })
        .catch((error) => {
          console.log("ERROR:", error, error.response);
          this.handleError("Coś poszło nie tak");
        });
    },
    modify() {
      if (!this.$refs.placeform.validate()) {
        return;
      }

      this.beforeRequest();

      P1Service.updateMedicalPlace(this.medicalPlace)
        .then((reply) => {
          console.log("REPLY:", reply);
          this.reset();
          this.$emit(
            "success",
            "Miejsce udzielania świadczeń zostało zapisane."
          );
          this.show = false;
        })
        .catch((error) => {
          console.log("ERROR:", error, error.response);
          this.handleError("Coś poszło nie tak");
        });
    },
    handlePositiveAction() {
      if (this.edit) {
        this.modify();
      } else {
        this.add();
      }
    },
    cancel() {
      this.$emit("update:medicalPlace", this.initialMedicalPlace);
      this.show = false;
    },
  },
};
</script>